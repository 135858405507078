import { HttpClient } from '@angular/common/http';
import { Injectable, computed, inject, signal } from '@angular/core';
import { Roles } from '@beathletics/api-interfaces';
import type { AppFeature } from '@prisma/client';
import { AuthService } from './auth.service';
import { toSignal } from '@angular/core/rxjs-interop';
import { InjectionToken } from '@angular/core';
import { firstValueFrom } from 'rxjs';

export const FEATURES_TOKEN = new InjectionToken<AppFeature[]>('Features Token');

@Injectable({
  providedIn: 'root',
})
export class AppFeatureService {
  http = inject(HttpClient);
  authService = inject(AuthService);
  features = [] as AppFeature[];

  roles = toSignal(this.authService.getUserRoles(), {
    initialValue: [] as Roles[],
  });

  static factory(http: HttpClient, service: AppFeatureService) {
    return () => service.init();
  }

  async init() {
    this.features = await firstValueFrom(this.http.get<AppFeature[]>('/api/app-feature'));
  }

  featureAccess = computed(() => {
    return this.features.reduce(
      (acc, feature) => {
        acc[feature.name] =
          !feature.anonymousDisabled || !!feature.rolesAllowed.find((role) => this.roles().includes(role as Roles));
        return acc;
      },
      {} as Record<string, boolean>,
    );
  });
}
