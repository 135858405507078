import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { IPrimeTreeChild, IPrimeTreeParent } from '@beathletics/api-interfaces';
import { Observable, map, of } from 'rxjs';
import { TreeSelectModule } from 'primeng/treeselect';
import { PrimeTemplate } from 'primeng/api';
import { MatIcon } from '@angular/material/icon';
import { AsyncPipe } from '@angular/common';

@Component({
  selector: 'beathletics-prime-tree-selector',
  template: `
    <div class="prime-selector w-full" [style.padding-top]="paddingTop" [style.padding-bottom]="paddingBottom">
      <span class="p-float-label">
        <p-treeSelect
          [appendTo]="appendTo"
          [selectionMode]="selectionMode"
          [placeholder]="label"
          [filter]="withFilter"
          [showClear]="withClearBtn"
          [options]="dataTree || []"
          [formControl]="selectedDataForm"
          (onNodeSelect)="editLabelWithFormValue($any($event))"
          (onNodeUnselect)="editLabelWithFormValue($any($event))"
          (onClear)="selectionChange.emit([])"
        >
          <ng-template pTemplate="value" let-value let-label>
            @if (selectedDataLabels$ | async; as value) {
              {{ value }}
            } @else {
              {{ label }}
            }
          </ng-template>

          @if (titleAndSubtitleLabel; as a) {
            <ng-template pTemplate="node" let-value>
              <div class="flex flex-col">
                <span class="text-sm">{{ value?.data?.[a.titleProp] }}</span>
                <span style="font-size: 10px; white-space: nowrap" class="text-gray-500">
                  {{ value?.data?.[a.subtitleProp] }}</span
                >
              </div>
            </ng-template>
          }
          @if (closeIcon) {
            <ng-template pTemplate="closeicon"
              ><mat-icon class="text-{{ closeIconColor }} text-xl">{{ closeIcon }}</mat-icon></ng-template
            >
          }
        </p-treeSelect>
        <label for="treeselect">{{ label }}</label>
      </span>
    </div>
  `,
  standalone: true,
  imports: [TreeSelectModule, ReactiveFormsModule, PrimeTemplate, MatIcon, AsyncPipe],
})
export class PrimeTreeSelectorComponent<TData, TParentData> {
  private _dataRefProperty!: keyof TData;
  selectedDataForm = new FormControl();
  selectedDataLabels$: Observable<string | null> = of(null);

  lastEmittedData: (
    | string
    | (NonNullable<TData> | NonNullable<TParentData>)[keyof TData & keyof TParentData]
    | undefined
  )[] = [];

  @Input() selectionMode: 'single' | 'multiple' | 'checkbox' = 'checkbox';
  @Input() appendTo: 'self' | 'body' = 'self';
  @Input() label = '';
  @Input() paddingTop = '2rem';
  @Input() paddingBottom = '0';
  @Input() withFilter = true;
  @Input() withClearBtn = true;
  @Input() filterParents = true;
  @Input() closeIcon?: string;
  @Input() closeIconColor = 'green-700';
  @Input() titleAndSubtitleLabel?: { titleProp: string; subtitleProp: string };
  @Input() dataTree: IPrimeTreeParent<TData, TParentData>[] | null = [];
  @Input() set dataRefProperty(value: keyof TData) {
    this._dataRefProperty = value;
  }
  get dataRefProperty(): keyof TData {
    return this._dataRefProperty;
  }
  @Input() set selectedData(dataToSelect: string | string[] | TData[] | undefined) {
    if (dataToSelect && this.dataTree && this.dataTree.length > 0) {
      const nodes = this.dataTree
        .map((parent) => {
          let keepFullGroup = true;
          for (const child of parent.children) {
            const key = this.dataRefProperty;
            const toTest = child.data?.[key] as string & TData;
            if (typeof dataToSelect === 'string') {
              if (dataToSelect !== toTest) {
                keepFullGroup = false;
                break;
              }
            } else {
              if (!dataToSelect.includes(toTest)) {
                keepFullGroup = false;
                break;
              }
            }
          }
          if (keepFullGroup) {
            return [parent, ...parent.children];
          }
          return parent.children;
        })
        .flat()
        .filter((child) => {
          if (typeof child.data !== 'string') {
            const key = this.dataRefProperty;
            const toTest = (child.data as TData)?.[key] as string & TData;
            return toTest && typeof dataToSelect === 'string' ? dataToSelect === toTest : dataToSelect.includes(toTest);
          }
          return true;
        });
      this.selectedDataForm.setValue(nodes);
      this.selectedDataLabels$ = of(this.selectedDataForm.value).pipe(map(this.setTreeSelectLabel));
    } else {
      this.selectedDataForm.setValue(null);
      this.selectedDataLabels$ = of(null);
    }
  }
  @Output() selectionChange = new EventEmitter<(TData | TParentData | string)[]>();

  setTreeSelectLabel = (
    nodes:
      | IPrimeTreeParent<TData, TParentData>
      | IPrimeTreeChild<TData, TParentData>
      | (IPrimeTreeParent<TData, TParentData> | IPrimeTreeChild<TData, TParentData>)[],
  ) => {
    if (nodes) {
      let emit = false;

      if (!Array.isArray(nodes)) {
        nodes = [nodes];
      }
      const data = nodes.filter((n) => typeof n.data !== 'string');

      const labels = data.map((node) =>
        this.titleAndSubtitleLabel
          ? node.data?.[this.titleAndSubtitleLabel.titleProp as keyof (TData | TParentData)]
          : node.data
            ? node.data?.[this.dataRefProperty as keyof (TData | TParentData)]
            : node.label,
      );
      const toTest = labels.sort();

      if (this.lastEmittedData.length !== toTest.length) {
        emit = true;
      } else {
        for (const [i, data] of this.lastEmittedData.entries()) {
          if (data !== toTest[i]) {
            emit = true;
            break;
          }
        }
      }

      if (emit) {
        this.lastEmittedData = toTest;
        this.selectionChange.emit(data.map((node) => (node?.data ? node.data : node.label)));
      }

      return labels.join(', ');
    } else {
      this.selectionChange.emit([]);
      return null;
    }
  };

  editLabelWithFormValue(event: {
    node: IPrimeTreeParent<TData, TParentData> | IPrimeTreeChild<TData, TParentData>;
    originalEvent: PointerEvent;
  }) {
    if (this.selectionMode === 'checkbox') {
      const formData = [...this.selectedDataForm.value];
      this.selectedDataForm.setValue(
        formData.filter(
          (n: IPrimeTreeParent<TData, TParentData> | IPrimeTreeChild<TData, TParentData>) =>
            n?.selectable !== false && (this.filterParents ? !('children' in n) : true),
        ),
      );
    }
    if (
      this.selectionMode === 'single' &&
      ((event?.node && !this.selectedDataForm.value) || event?.node?.label !== this.selectedDataForm.value?.label)
    ) {
      this.selectedDataForm.setValue(event.node);
    }
    this.selectedDataLabels$ = of(this.selectedDataForm.value).pipe(map(this.setTreeSelectLabel));
  }
}
