export enum ECompetitionFeature {
  DID_NOT_START = 'DNS',
  DID_NOT_FINISH = 'DNF',
  DISQUALIFIED = 'DQ',
  NOT_RECEIVABLE = 'NR',
  NO_MARK = 'NM',
  NO_HEIGHT = 'NH',
  YELLOW_CARD = 'yC',
  SECOND_YELLOW_CARD = 'yRC',
  RED_CARD = 'RC',
  FALSE_START = 'Fn',
  RETIRED_FROM_COMPETITION = 'R',
  QUALIFIED_BY_TRACK_OR_FIELD = 'Q',
  QUALIFIED_BY_PERFORMANCE = 'q',
  ADVANCED_TO_NEXT_ROUND_BY_REFEREE = 'qR',
  ADVANCED_TO_NEXT_ROUND_BY_JURY_OF_APPEAL = 'qJ',
  BENT_KNEE = '>',
  LOSS_OF_CONTACT = '~',
  AUTOMATIC_TIMING = 'a',
  HAND_TIMING = 'h',
  PERFORMANCE_ACHIEVED_AT_ALTITUDE = 'A',
  OVERSIZED_TRACK = 'OT',
}

export enum EPerfType {
  TIME = 'time',
  DISTANCE = 'distance',
  POINTS = 'points',
  UNKNOWN = 'unknown',
}

export enum DisciplineGroups {
  Sprint = 'Sprint',
  Fond = 'Fond',
  Demi_fond = 'Demi-fond',
  Haies = 'Haies',
  Relais = 'Relais',
  Saut = 'Saut',
  Lancer = 'Lancer',
  Multiple = 'Epreuves multiples',
}

export const eventTypeResultTypeToEPerfType = (type?: string | null) => {
  if (type === 'Time') {
    return EPerfType.TIME;
  } else if (type === 'Distance') {
    return EPerfType.DISTANCE;
  } else if (type === 'Points') {
    return EPerfType.POINTS;
  }
  return EPerfType.UNKNOWN;
};

// ! this function is replicated in the backend ! if you make a change here, make sure to change it there too
// => libs/common-back/src/lib/utils/perf-formatter.ts
export function perfFormatterFront(
  value: string | number | undefined | null,
  perfType: EPerfType | string | null = EPerfType.UNKNOWN,
  eventTypeGroup?: DisciplineGroups | null,
): string {
  if (value) {
    const cF = Object.values(ECompetitionFeature);
    if (cF.includes(value as ECompetitionFeature)) {
      return value as ECompetitionFeature;
    } else if (
      perfType?.toLowerCase() === EPerfType.DISTANCE ||
      eventTypeGroup === DisciplineGroups.Lancer ||
      eventTypeGroup === DisciplineGroups.Saut
    ) {
      if (typeof value === 'string' && value.includes('m')) {
        const v = value.split('m');
        if (+v[1] < 10 && v[1].length === 1) {
          v[1] = v[1] + 0;
        }
        return v[0] + 'm' + v[1];
      } else if (('' + value).includes('.')) {
        const val = (+value).toFixed(2);
        const v = ('' + val).split('.');
        if (+v[1] < 10) {
          v[1] = '0' + +v[1];
        }
        return v[0] + 'm' + v[1];
      } else if (!isNaN(+value)) {
        return value + 'm' + '00';
      } else {
        return '' + value;
      }
    } else if (
      perfType === EPerfType.POINTS ||
      eventTypeGroup === DisciplineGroups.Multiple
    ) {
      return value + ' pts';
    } else if (perfType === EPerfType.TIME || eventTypeGroup) {
      if (
        eventTypeGroup === DisciplineGroups.Sprint ||
        eventTypeGroup === DisciplineGroups.Haies
      ) {
        if (!isNaN(+value)) {
          return (+value / 1000).toFixed(2).replace('.', '"');
        } else {
          return '' + value;
        }
      } /* if (
        eventTypeGroup === DisciplineGroups.Fond ||
        eventTypeGroup === DisciplineGroups.Demi_fond ||
        eventTypeGroup === DisciplineGroups.Relais
      ) */ else {
        const ms = +value;
        const h = Math.floor(ms / 3600000);
        const m = Math.floor((ms % 3600000) / 60000);
        const s = Math.floor((ms % 60000) / 1000);
        const c = Math.floor((ms % 1000) / 10);
        return (
          (h > 0 ? h + ':' : '') +
          (m > 0 ? (m > 9 ? m : '0' + m) + ':' : '00:') +
          ((s > 0 ? (s > 9 ? s : '0' + s) : '00') + '.') +
          (c > 0 ? (c > 9 ? c : '0' + c) : '00')
        );
      }
    }
    return '' + value;
  } else {
    return '';
  }
}

export function perfFormatterExport(
  value: string | number | undefined | null,
  perfType: EPerfType = EPerfType.UNKNOWN,
  eventTypeGroup?: DisciplineGroups | null,
): string {
  if (value) {
    const cF = Object.values(ECompetitionFeature);
    if (cF.includes(value as ECompetitionFeature)) {
      return value as ECompetitionFeature;
    } else if (
      perfType === EPerfType.DISTANCE ||
      eventTypeGroup === DisciplineGroups.Lancer ||
      eventTypeGroup === DisciplineGroups.Saut
    ) {
      if (typeof value === 'string' && value.includes('m')) {
        const v = value.split('m');
        if (+v[1] < 10 && v[1].length === 1) {
          v[1] = v[1] + 0;
        }
        return v[0] + '.' + v[1];
      } else if (('' + value).includes('.')) {
        const val = (+value).toFixed(2);
        const v = ('' + val).split('.');
        if (+v[1] < 10) {
          v[1] = '0' + +v[1];
        }
        return v[0] + '.' + v[1];
      } else if (!isNaN(+value)) {
        return value + '.' + '00';
      } else {
        return '' + value;
      }
    } else if (
      perfType === EPerfType.POINTS ||
      eventTypeGroup === DisciplineGroups.Multiple
    ) {
      return '' + value;
    } else if (perfType === EPerfType.TIME || eventTypeGroup) {
      return (+value / 1000).toFixed(2);
    }
    return '' + value;
  } else {
    return '';
  }
}
