import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';

@Component({
  selector: 'beathletics-cell-observable-renderer',
  templateUrl: './cell-observable-renderer.component.html',
  styleUrls: ['./cell-observable-renderer.component.scss'],
  standalone: true,
})
export class CellObservableRendererComponent implements ICellRendererAngularComp {
  text!: string;

  agInit(param: ICellRendererParams) {
    if (param.data.athlete) {
      param.data.athlete.subscribe((a: any) => (this.text = a.firstname + ' ' + a.lastname));
    } else if (param.data.event) {
      param.data.event.subscribe((e: any) => (this.text = e.name));
    } else if (param.data.organization) {
      param.data.organization.subscribe((o: any) => (this.text = o.name));
    }
  }

  refresh(params: ICellRendererParams): boolean {
    if (params.data.athlete) {
      params.data.athlete.subscribe((a: any) => (this.text = a.firstname + ' ' + a.lastname));
    } else if (params.data.event) {
      params.data.event.subscribe((e: any) => (this.text = e.name));
    } else if (params.data.organization) {
      params.data.organization.subscribe((o: any) => (this.text = o.name));
    }
    return false;
  }
}
