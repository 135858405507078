import { Component, Input } from '@angular/core';
import { NgOptimizedImage } from '@angular/common';
import { ThemeClasses } from '@beathletics/api-interfaces';

@Component({
  selector: 'beathletics-main-footer',
  templateUrl: './main-footer.component.html',
  styleUrls: ['./main-footer.component.scss'],
  standalone: true,
  imports: [NgOptimizedImage],
})
export class MainFooterComponent {
  @Input() theme_color: ThemeClasses | null = null;
}
