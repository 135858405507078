import { Component, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatButton } from '@angular/material/button';

@Component({
  selector: 'beathletics-notification-dialog',
  template: `
    <section class="text-center max-w-[600px]">
      <h3 class="text-lg font-medium mb-5">{{ notification.title }}</h3>
      <hr />

      @for (text of notification.texts; track text) {
        <p class="m-5 break-words">{{ text }}</p>
      }

      <button mat-raised-button color="primary" class="mt-1" (click)="closeDialog()">OK</button>
    </section>
  `,
  standalone: true,
  imports: [MatButton],
})
export class NotificationDialogComponent {
  notification = inject(MAT_DIALOG_DATA);
  private dialogRef = inject<MatDialogRef<NotificationDialogComponent>>(MatDialogRef);

  closeDialog() {
    this.dialogRef.close();
  }
}
