import { Directive, Input, TemplateRef, ViewContainerRef, inject } from '@angular/core';
import { Roles } from '@beathletics/api-interfaces';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, map, switchMap } from 'rxjs';
import { AuthService } from './auth.service';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[hasOneOfRoles]',
})
@UntilDestroy()
export class HasOneOfRoleDirective {
  private templateRef = inject<TemplateRef<any>>(TemplateRef);
  private viewContainer = inject(ViewContainerRef);
  private auth = inject(AuthService);

  @Input()
  set hasOneOfRoles(role: Roles[]) {
    this.roles$.next(role);
  }

  roles$ = new BehaviorSubject<Roles[]>([] as Roles[]);
  isShow = false;

  constructor() {
    this.roles$
      .pipe(
        untilDestroyed(this),
        map((roles) => roles.filter((role) => Object.values(Roles).includes(role))),
        switchMap((roles: Roles[]) => this.auth.hasAtLeastOneRole(roles)),
      )
      .subscribe((isAuthorized) => {
        if (isAuthorized) {
          if (!this.isShow) {
            this.viewContainer.createEmbeddedView(this.templateRef);
            this.isShow = true;
          }
        } else {
          this.viewContainer.clear();
          this.isShow = false;
        }
      });
  }
}
