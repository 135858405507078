import { DatePipe } from '@angular/common';
import { Component, input } from '@angular/core';
import { ReplacePipe } from '../../pipes/replace.pipe';

@Component({
  selector: 'beathletics-date-circle',
  template: `
    <div
      class="h-[70px] w-[70px] min-w-[70px] rounded-full flex flex-col justify-center items-center text-white shadow-black drop-shadow-lg m-2"
      [class]="themeClass()"
    >
      <span class="uppercase font-medium text-xs leading-[15px]">{{ date() | date: 'EEE' | replace: '.' : '' }}</span>
      <span class="font-black text-3xl leading-[22px]">{{ date() | date: 'dd' }}</span>
      <span class="font-medium text-md leading-[14px]">{{ date() | date: 'MMM' | replace: '.' : '' }}</span>
    </div>
  `,
  standalone: true,
  imports: [DatePipe, ReplacePipe],
})
export class DateCircleComponent {
  date = input.required<Date | string | number>();
  textColor = input<string | undefined>('white');
  themeClass = input<string | undefined>('material');
}
