import { APP_INITIALIZER, InjectionToken, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KeycloakAngularModule } from 'keycloak-angular';
import { NgxsModule } from '@ngxs/store';
import { AuthState } from './auth.state';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { HasRoleDirective } from './has-role.directive';
import { HasOneOfRoleDirective } from './has-one-of-role.directive';
import { HasOnlyRoleDirective } from './has-only-role.directive';
import { HasOnlyOneOfRolesDirective } from './has-only-one-of-roles.directive';
import { HttpClient } from '@angular/common/http';
import { AppFeatureService } from './appFeature.service';
import { hasFeatureAccessDirective } from './has-feature-access.directive';

export const KEYCLOAK_URL = new InjectionToken<string>('KeycloakUrl');

@NgModule({
  imports: [
    CommonModule,
    MatSnackBarModule,
    KeycloakAngularModule,
    NgxsModule.forFeature([AuthState]),
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: AppFeatureService.factory,
      multi: true,
      deps: [HttpClient, AppFeatureService],
    },
  ],
  declarations: [
    HasRoleDirective,
    HasOneOfRoleDirective,
    HasOnlyRoleDirective,
    HasOnlyOneOfRolesDirective,
    hasFeatureAccessDirective,
  ],
  exports: [
    HasRoleDirective,
    HasOneOfRoleDirective,
    HasOnlyRoleDirective,
    HasOnlyOneOfRolesDirective,
    hasFeatureAccessDirective,
  ],
})
export class FrontAuthModule {}
