<mat-sidenav-container class="sidenav-container" autosize>
  @let isSideNavOpen = subMenuItems && subMenuItems.length > 0 && !hideSubMenu;
  <mat-sidenav
    #drawer
    class="sidenav transition-all duration-500 ease-in-out min-h-screen {{ isSideNavOpen ? '!w-[200px]' : '!w-[64px]' }}"
    fixedInViewport
    [attr.role]="isHandset() ? 'dialog' : 'navigation'"
    [mode]="isHandset() ? 'over' : 'side'"
    [opened]="!isHandset()"
  >
    <div class="flex justify-start flex-row h-full">
      <mat-nav-list class="max-w-16 flex-col !flex text-center bg-[#aa5a39]">
        <mat-list-item class="h-16 w-16" class="shrink-0" (click)="toggleNav()">
          <img width="32" height="32" alt="BeAthletics" ngSrc="/assets/logo-192.png" />
        </mat-list-item>

        @for (menuItem of menu; track menuItem) {
          @if (menuItem.hasRole$ | async) {
            @if (menuItem.link) {
              <a
                class="shrink-0 white-text"
                mat-list-item
                [routerLink]="menuItem.link"
                [matTooltip]="menuItem.label"
                routerLinkActive="inverted"
              >
                <mat-icon class="label">{{ menuItem.icon }}</mat-icon>
              </a>
            }
            @if (menuItem.href) {
              <a class="white-text" mat-list-item [href]="menuItem.href">
                <mat-icon class="label">{{ menuItem.icon }}</mat-icon>
              </a>
            }
          }
        }
        <div class="flex-grow"></div>
        <a href="/directus/" target="_blank" *hasRole="rolesType.Admin">
          <button class="shrink-0 bg-transparent white-text" mat-list-item>
            <img style="filter: invert(100%)" width="32" height="32" alt="Directus" ngSrc="/assets/directus.svg" />
          </button>
        </a>
        <button class="shrink-0 bg-transparent white-text" mat-list-item (click)="emitLogout(true)">
          <mat-icon class="label">power_settings_new</mat-icon>
        </button>
      </mat-nav-list>
      <!-- sub menu -->
      @if (subMenuItems && subMenuItems.length > 0 && !hideSubMenu) {
        <mat-nav-list class="">
          @for (menuItem of subMenuItems; track menuItem.link) {
            <a mat-list-item [routerLink]="menuItem.link" [matTooltip]="menuItem.link" routerLinkActive="icon-active">
              <span class="label">{{ menuItem.label }}</span>
            </a>
          }
        </mat-nav-list>
      }
    </div>
  </mat-sidenav>

  <mat-sidenav-content>
    <div class="main-content">
      <ng-content></ng-content>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
