import { Directive, ElementRef, Input, OnInit, inject } from '@angular/core';
import { MatInput } from '@angular/material/input';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[matInputAutofocus]',
  standalone: true,
})
export class AutofocusDirective implements OnInit {
  private matInput = inject(MatInput);
  private elRef = inject<ElementRef<HTMLInputElement>>(ElementRef);

  @Input() autofocusMatInput = false;
  @Input() autofocusSelectValue = false;

  ngOnInit(): void {
    setTimeout(() => {
      if (this.autofocusMatInput) {
        this.matInput.focus();
      }

      if (this.autofocusSelectValue) {
        this.elRef.nativeElement.setSelectionRange(0, this.elRef.nativeElement.value.length);
      }
    });
  }
}
