import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatChipListbox, MatChipOption } from '@angular/material/chips';

@Component({
  selector: 'beathletics-toggle-chip-list',
  templateUrl: './toggle-chip-list.component.html',
  styleUrls: ['./toggle-chip-list.component.css'],
  standalone: true,
  imports: [MatChipListbox, ReactiveFormsModule, MatChipOption],
})
export class ToggleChipListComponent {
  @Input() chips: string[] | undefined | null;
  @Output() selectionTagsChange = new EventEmitter();

  formControl = new FormControl([] as string[]);

  toggleSelect(keyword: string) {
    const array = this.formControl.value;
    if (array)
      if (array.includes(keyword)) {
        array.splice(array.indexOf(keyword), 1);
      } else {
        array.push(keyword);
      }
    this.formControl.patchValue(array);
    this.selectionTagsChange.emit(this.formControl.value);
  }
}
