import { Component } from '@angular/core';
import { INoRowsOverlayParams, INoRowsOverlayComp } from '@ag-grid-community/core';

@Component({
  selector: 'beathletics-grid-no-row-overlay',
  templateUrl: './grid-no-row-overlay.component.html',
  styleUrls: ['./grid-no-row-overlay.component.scss'],
  standalone: true,
})
export class GridNoRowOverlayComponent implements INoRowsOverlayComp {
  params!: INoRowsOverlayParams;

  agInit(params: INoRowsOverlayParams): void {
    this.params = params;
  }

  getGui(): HTMLElement {
    return document.createElement('<div>');
  }
}
