import { Component, inject } from '@angular/core';
import { FormControl, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { MatDialogRef, MatDialogClose } from '@angular/material/dialog';
import { EContactSubject } from '@beathletics/api-interfaces';
import { EMAIL_VALIDATION_REGEX } from '@beathletics/utils-shared';
import { TranslocoDirective } from '@jsverse/transloco';
import { MatFormField, MatLabel, MatError, MatHint } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { MatButton } from '@angular/material/button';

@Component({
  selector: 'beathletics-contact-form',
  templateUrl: './contact-form.component.html',
  styles: [],
  standalone: true,
  imports: [
    TranslocoDirective,
    ReactiveFormsModule,
    MatFormField,
    MatLabel,
    MatInput,
    MatError,
    MatHint,
    MatSelect,
    MatOption,
    MatButton,
    MatDialogClose,
  ],
})
export class ContactFormComponent {
  dialogRef = inject(MatDialogRef<ContactFormComponent>);

  subjects = Object.values(EContactSubject);

  contactForm = new FormGroup({
    firstName: new FormControl(''),
    lastName: new FormControl(''),
    email: new FormControl('', [Validators.email, Validators.pattern(EMAIL_VALIDATION_REGEX)]),
    subject: new FormControl(''),
    message: new FormControl(''),
  });

  sendContactEmail() {
    if (this.contactForm.valid) {
      this.dialogRef.close(this.contactForm.value);
    }
  }
}
