<footer
  class="w-full flex bg-footer-bottom-{{
    theme_color?.svgId
  }}-mobile lg:bg-none flex-col items-center lg:bg-transparent lg:flex-row lg: justify-between lg:relative"
>
  <div
    class="infos-container grid grid-cols-1 justify-items-center md:justify-items-start  md:grid-cols-[minmax(auto,55px)_100px_minmax(300px,auto)] items-center min-h-[200px] text-xs lg:min-w-[680px]
    bg-left-bottom bg-no-repeat bg-none lg:bg-footer-bottom-{{
      theme_color?.svgId
    }}-lg  w-full pb-[20px] pt-[30px] text-black lg:text-white"
  >
    <div class="logo-lbfa block md:col-start-2 bg-white rounded-3xl w-[100px] h-[128px]">
      <img class="w-[80px] h-auto m-[10px]" [ngSrc]="'./assets/logo-LBFA-cut.png'" width="222" height="300" />
    </div>
    <div class="infos-text ml-[20px] pt-[15px] {{ theme_color?.font }} ">
      <p>
        Be<b>Athletics</b><br />
        est la plateforme de la LBFA développée par
        <a href="https://www.betrail.run">Betrail.run</a> <br /><br />
        Ce site regroupe tous les résultats athlétiques en Belgique.
      </p>
    </div>
  </div>

  <div class="logos-sponsors w-[300px] lg:w-[500px] h-auto m-auto mb-[50px]">
    <img
      class="lg:hidden"
      [class.homeHideLogo]="theme_color?.themeId === 'home'"
      ngSrc="./assets/Allianz-white.png"
      height="1294"
      width="5000"
    />
    <img
      class="hidden lg:block pr-12"
      [class.homeDisplayLogo]="theme_color?.themeId === 'home'"
      ngSrc="./assets/Allianz-blue.png"
      height="1294"
      width="5000"
    />
  </div>
</footer>
