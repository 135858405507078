const apiTag = '[Shared Data API] ';

export class LoadSharedData {
  static readonly type = apiTag + 'Load all shared state data';
}

export class LoadCategories {
  static readonly type = apiTag + 'Load all categories';
}

export class LoadClubs {
  static readonly type = apiTag + 'Load all clubs';
}

export class LoadCountries {
  static readonly type = apiTag + 'Load all countries';
}

export class LoadEventTypes {
  static readonly type = apiTag + 'Load all event types';
}

export class LoadFederations {
  static readonly type = apiTag + 'Load all federations';
}
