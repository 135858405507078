import { Pipe, PipeTransform } from '@angular/core';
import { IDiscipline, IRegistrationPricing, ShopDiscipline } from '@beathletics/api-interfaces';

type DisciplineToSort = (IDiscipline | ShopDiscipline) & {
  covered?: boolean;
  formattedCategories?: string;
  prices?: {
    base: {
      [option: string]: IRegistrationPricing | null;
    };
    exceptions: {
      [clubs: string]: {
        [option: string]: IRegistrationPricing | undefined;
      };
    };
  };
};

@Pipe({
  name: 'disciplineSorter',
  standalone: true,
})
export class DisciplineSorterPipe implements PipeTransform {
  transform(value?: DisciplineToSort[] | null): DisciplineToSort[] {
    if (!value || value.length === 0) {
      return [];
    } else {
      return value.sort((a, b) => (a.eventType?.sort_order || 0) - (b.eventType?.sort_order || 0));
    }
  }
}
