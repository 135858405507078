import { Component } from '@angular/core';

@Component({
  standalone: true,
  selector: 'beathletics-rounded-card',
  template: `
    <figure
      class="w-full bg-white shadow-md overflow-hidden sm:rounded-xl flex flex-row"
    >
      <ng-content select="[left-content]"></ng-content>
      <ng-container
        ><div class="grow"><ng-content></ng-content></div
      ></ng-container>
      <ng-content select="[right-content]"></ng-content>
    </figure>
  `,
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
})
export class RoundedCardComponent {}
