import { Injectable, Type, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';

export abstract class TypedDialog<DialogData, DialogResult> {
  protected data: DialogData = inject(MAT_DIALOG_DATA);
  protected dialogRef: MatDialogRef<TypedDialog<DialogData, DialogResult>, DialogResult> = inject(MatDialogRef);
}

@Injectable({ providedIn: 'root' })
export class TypedDialogService {
  public dialog = inject(MatDialog);

  open = <DialogData, DialogResult>(
    component: Type<TypedDialog<DialogData, DialogResult>>,
    config: MatDialogConfig<DialogData> & { data: DialogData },
  ): MatDialogRef<TypedDialog<DialogData, DialogResult>, DialogResult> => this.dialog.open(component, config);
}
