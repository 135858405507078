<!-- <mat-form-field class="w-full" appearance="outline">
<mat-label>Filtre</mat-label> -->
<mat-chip-listbox
  class="mat-chip-list-stacked"
  aria-label="Video keywords"
  [multiple]="true"
  [formControl]="formControl"
  >
  @for (keyword of chips; track keyword) {
    <mat-chip-option
      color="accent"
      [value]="keyword"
      (click)="toggleSelect(keyword)"
      >
      {{ keyword }}
    </mat-chip-option>
  }
</mat-chip-listbox>
<!-- </mat-form-field> -->
