import { Pipe, PipeTransform } from '@angular/core';
import { perfFormatterFront, EPerfType, DisciplineGroups } from '../utils/perf-formatter';

@Pipe({
  name: 'perfFormatter',
  standalone: true,
})
export class PerfFormatterPipe implements PipeTransform {
  transform(
    value: string | number | undefined | null,
    perfType: EPerfType | string | null = EPerfType.UNKNOWN,
    eventTypeGroup?: DisciplineGroups | null,
  ): string {
    return perfFormatterFront(value, perfType, eventTypeGroup);
  }
}
