/* eslint-disable @typescript-eslint/no-empty-function */
import { Component, OnInit } from '@angular/core';
import { IFilterAngularComp } from '@ag-grid-community/angular';
import { IDoesFilterPassParams, IFilterParams } from '@ag-grid-community/core';
import { MatSelect } from '@angular/material/select';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatOption } from '@angular/material/core';

@Component({
  selector: 'beathletics-quick-select-filter',
  templateUrl: './quick-select-filter.component.html',
  standalone: true,
  imports: [MatSelect, ReactiveFormsModule, FormsModule, MatOption],
})
export class QuickSelectFilterComponent implements IFilterAngularComp {
  params!: IFilterParams;
  values = ['za', 'sd'];
  model = undefined;
  agInit(params: IFilterParams): void {
    this.params = params;
  }

  isFilterActive(): boolean {
    return true;
  }

  doesFilterPass(params: IDoesFilterPassParams): boolean {
    return params.data.year >= 2010;
  }

  getModel() {}

  setModel(model: any) {}

  updateFilter() {
    this.params.filterChangedCallback();
  }
}
