import { Component, ElementRef, ViewChild, EventEmitter, Output, Input, inject } from '@angular/core';

import { Router } from '@angular/router';
import { MatAutocompleteTrigger, MatAutocomplete } from '@angular/material/autocomplete';
import { CompetitionSearchResult } from '../../utils/interfaces';
import { AthleteSearchResult, PublicSearchResult } from '@beathletics/api-interfaces';
import { MatMiniFabButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatInput } from '@angular/material/input';
import { MatButtonToggleGroup, MatButtonToggle } from '@angular/material/button-toggle';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'beathletics-public-search-bar',
  templateUrl: './public-search-bar.component.html',
  styleUrls: ['./public-search-bar.component.scss'],
  standalone: true,
  imports: [
    MatMiniFabButton,
    MatIcon,
    MatProgressSpinner,
    MatInput,
    MatAutocompleteTrigger,
    MatAutocomplete,
    MatButtonToggleGroup,
    ReactiveFormsModule,
    FormsModule,
    MatButtonToggle,
    MatOption,
    DatePipe,
  ],
})
export class PublicSearchBarComponent {
  router = inject(Router);

  isOpen = false;
  currentSearchValue!: string;
  selectedSearch = 'athletes';

  @Input() searchAutocomplete!: PublicSearchResult;
  @Output() searchValue = new EventEmitter();

  @ViewChild('searchInput', { read: MatAutocompleteTrigger, static: true })
  searchInput!: MatAutocompleteTrigger;
  @ViewChild('searchInput', { static: true })
  searchNativeElem!: ElementRef<HTMLInputElement>;

  onSearchChange(value: string) {
    if (value != this.currentSearchValue) {
      this.searchValue.emit(value.trim());
      this.currentSearchValue = value.trim();
    }
    if (value.trim().length >= 3) {
      this.searchInput.openPanel();
      this.isOpen = true;
    } else {
      this.searchInput.closePanel();
      this.isOpen = false;
    }
  }

  retry() {
    this.searchValue.emit(this.currentSearchValue);
  }

  onSearchSelect(value: AthleteSearchResult | CompetitionSearchResult | { command: 'retry' }) {
    if ('command' in value && value.command === 'retry') {
      this.retry();
      return;
    }
    this.currentSearchValue = '';
    this.searchValue.emit('');
    if ('liveId' in value) {
      this.router.navigate(['athlete', value.liveId]);
    } else if ('eventNumber' in value) {
      this.router.navigate(['event', value.eventNumber]);
    }
  }

  setIsOpenParam(value: boolean) {
    this.isOpen = value;
  }
}
