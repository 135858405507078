import { BreakpointObserver } from '@angular/cdk/layout';
import { StepperOrientation } from '@angular/cdk/stepper';
import { Component, inject } from '@angular/core';
import { FormBuilder, Validators, ReactiveFormsModule } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { map, Observable } from 'rxjs';
import { MatStepper, MatStep, MatStepperNext, MatStepperPrevious, MatStepLabel } from '@angular/material/stepper';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatButton } from '@angular/material/button';
import { AsyncPipe } from '@angular/common';

@Component({
  selector: 'beathletics-register-athlete-dialog',
  template: `<p>Selection de l'athlete</p>
    <mat-stepper class="example-stepper" [orientation]="(stepperOrientation | async)!">
      <mat-step [stepControl]="firstFormGroup" label="Fill out your name">
        <form [formGroup]="firstFormGroup">
          <mat-form-field appearance="fill">
            <mat-label>Name</mat-label>
            <input matInput placeholder="Last name, First name" formControlName="firstCtrl" required />
          </mat-form-field>
          <div>
            <button mat-button matStepperNext>Next</button>
          </div>
        </form>
      </mat-step>
      <mat-step [stepControl]="secondFormGroup" label="Fill out your address">
        <form [formGroup]="secondFormGroup">
          <mat-form-field appearance="fill">
            <mat-label>Address</mat-label>
            <input matInput formControlName="secondCtrl" placeholder="Ex. 1 Main St, New York, NY" required />
          </mat-form-field>
          <div>
            <button mat-button matStepperPrevious>Back</button>
            <button mat-button matStepperNext>Next</button>
          </div>
        </form>
      </mat-step>
      <mat-step [stepControl]="thirdFormGroup" label="Fill out your phone number">
        <form [formGroup]="thirdFormGroup">
          <mat-form-field appearance="fill">
            <mat-label>Phone number</mat-label>
            <input matInput formControlName="thirdCtrl" placeholder="Ex. 12345678" required />
          </mat-form-field>
          <div>
            <button mat-button matStepperPrevious>Back</button>
            <button mat-button matStepperNext>Next</button>
          </div>
        </form>
      </mat-step>
      <mat-step>
        <ng-template matStepLabel>Done</ng-template>
        <p>You are now done.</p>
        <div>
          <button mat-button matStepperPrevious>Back</button>
        </div>
      </mat-step>
    </mat-stepper>
    <button mat-raised-button (click)="saveAthlete()">Enregistrer l'athlete<button></button></button> `,
  styles: [],
  standalone: true,
  imports: [
    MatStepper,
    MatStep,
    ReactiveFormsModule,
    MatFormField,
    MatLabel,
    MatInput,
    MatButton,
    MatStepperNext,
    MatStepperPrevious,
    MatStepLabel,
    AsyncPipe,
  ],
})
export class RegisterAthleteDialogComponent {
  private dialogRef = inject(MatDialogRef<RegisterAthleteDialogComponent>);
  private _formBuilder = inject(FormBuilder);
  breakpointObserver = inject(BreakpointObserver);
  firstFormGroup = this._formBuilder.group({
    firstCtrl: ['', Validators.required],
  });
  secondFormGroup = this._formBuilder.group({
    secondCtrl: ['', Validators.required],
  });
  thirdFormGroup = this._formBuilder.group({
    thirdCtrl: ['', Validators.required],
  });
  stepperOrientation = this.breakpointObserver
    .observe('(min-width: 800px)')
    .pipe(map(({ matches }) => (matches ? 'horizontal' : 'vertical')));

  saveAthlete() {
    this.dialogRef.close();
  }
}
