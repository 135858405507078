import { AfterViewInit, Component, ElementRef, HostListener, Input, ViewChild } from '@angular/core';

@Component({
  selector: 'beathletics-sticky-top-bar',
  template: `
    <div #stickyTopBar class="w-full" [class.sticky-top-bar]="fixTopBar">
      <ng-content></ng-content>
    </div>
  `,
  styles: [
    `
      .sticky-top-bar {
        position: fixed;
        top: 0;
        overflow: hidden;
        z-index: 99;
      }
    `,
  ],
  standalone: true,
})
export class StickyTopBarComponent implements AfterViewInit {
  fixTopBar = false;
  topBarHeight = 0;
  topBarPos = 0;

  @ViewChild('stickyTopBar') barElement!: ElementRef;
  @HostListener('window:scroll', ['$event']) handleScroll() {
    const windowScroll = window.scrollY - this.topBarHeight;
    if (windowScroll >= this.topBarPos) {
      this.fixTopBar = true;
    } else {
      this.fixTopBar = false;
    }
  }

  ngAfterViewInit() {
    this.topBarPos = this.barElement.nativeElement.offsetTop;
    this.topBarHeight = this.barElement.nativeElement.offsetHeight;
  }
}
