import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DropdownModule } from 'primeng/dropdown';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MultiSelectModule } from 'primeng/multiselect';
import { PrimeTemplate } from 'primeng/api';

interface IPrimeListItem {
  label: string;
  data?: unknown;
}

@Component({
  selector: 'beathletics-prime-list-selector',
  template: `
    <div class="prime-selector w-full" [style.padding-top]="paddingTop" [style.padding-bottom]="paddingBottom">
      <span [class.p-float-label]="floatingLabel">
        @if (selectionMode === 'single') {
          <p-dropdown
            optionLabel="label"
            [appendTo]="appendTo"
            [placeholder]="label"
            [filter]="withFilter"
            [showClear]="withClearBtn"
            [options]="dataList"
            [(ngModel)]="selectedDataValue"
            (onChange)="emitSelection($event)"
            (onClear)="selectionChange.emit([])"
          ></p-dropdown>
        } @else {
          <p-multiSelect
            optionLabel="label"
            [appendTo]="appendTo"
            [placeholder]="label"
            [filter]="withFilter"
            [showClear]="withClearBtn"
            [showHeader]="withListHeader"
            [options]="dataList"
            [(ngModel)]="selectedDataValue"
            (onChange)="emitSelection($event)"
            (onClear)="selectionChange.emit([])"
          >
            @if (customOverview) {
              <ng-template pTemplate="selectedItems" let-items>
                <div [style.max-width]="customOverviewWidth" class="whitespace-nowrap overflow-hidden text-ellipsis">
                  @for (item of items; track item) {
                    {{ item.label }}
                    @if (!$last) {
                      ,
                    }
                  }
                </div>
              </ng-template>
            }
          </p-multiSelect>
        }
        @if (floatingLabel) {
          <label for="select">{{ label }}</label>
        }
      </span>
    </div>
  `,
  standalone: true,
  imports: [DropdownModule, ReactiveFormsModule, FormsModule, MultiSelectModule, PrimeTemplate],
})
export class PrimeListSelectorComponent {
  selectedDataValue?: IPrimeListItem | IPrimeListItem[];

  @Input() selectionMode: 'single' | 'checkbox' = 'single';
  @Input() appendTo: 'self' | 'body' | HTMLElement = 'self';
  @Input() floatingLabel = true;
  @Input() label = '';
  @Input() paddingTop = '2rem';
  @Input() paddingBottom = '0';
  @Input() withFilter = true;
  @Input() withClearBtn = true;
  @Input() withListHeader = true;
  @Input() customOverview = false;
  @Input() customOverviewWidth = '18rem';
  @Input() dataList: IPrimeListItem[] = [];
  @Input() dataRefProperty?: string;
  @Input() set selectedData(dataToSelect: string | string[] | null | undefined) {
    if (dataToSelect && this.dataList?.length > 0) {
      const nodes = this.dataList.filter((node) => {
        if (!this.dataRefProperty) {
          return Array.isArray(dataToSelect) ? dataToSelect.includes(node.label) : node.label === dataToSelect;
        } else {
          const key = this.dataRefProperty as keyof typeof node.data;
          return Array.isArray(dataToSelect)
            ? dataToSelect.includes((node.data as unknown & { [refKey: string]: string })[key])
            : node.data?.[key] === dataToSelect;
        }
      });
      this.selectedDataValue = this.selectionMode === 'checkbox' ? nodes : nodes[0];
    } else {
      this.selectedDataValue = undefined;
    }
  }
  @Output() selectionChange = new EventEmitter<unknown[]>();

  emitSelection(selected: { value: IPrimeListItem | IPrimeListItem[] }) {
    const data = selected?.value ? (Array.isArray(selected.value) ? selected.value : [selected.value]) : [];
    this.selectionChange.emit(data.map((item) => (item?.data ? item.data : item.label)));
  }
}
