import { Component } from '@angular/core';

import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';

@Component({
  selector: 'beathletics-checkbox-renderer',
  template: ` <input type="checkbox" (click)="checkedHandler($event)" [checked]="params.value" /> `,
  standalone: true,
})
export class CheckboxRendererComponent implements ICellRendererAngularComp {
  params!: ICellRendererParams;

  agInit(params: ICellRendererParams): void {
    this.params = params;
  }

  checkedHandler(event: MouseEvent) {
    const checked = (event?.target as any)?.checked;
    const colId = this.params?.column?.getId();
    colId && this.params.node.setDataValue(colId, checked);
  }

  refresh(params: ICellRendererParams): boolean {
    this.params = params;
    return true;
  }
}
