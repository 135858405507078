<mat-form-field
  class="w-full"
  [class.form-field-no-pb]="removePadding"
  [appearance]="appearance"
  [subscriptSizing]="removePadding ? 'dynamic' : 'fixed'"
  *transloco="let t"
  class="w-full"
>
  <mat-label>{{ t('SEARCH_ATHLETE') }}</mat-label>
  <input
    type="text"
    spellcheck="false"
    class="search-bar"
    #searchInput
    matInput
    cdkFocusInitial
    matInputAutofocus
    [autofocusMatInput]="autofocus"
    [placeholder]="t('SEARCH_NAME_OR_BIB')"
    [matAutocomplete]="autocomplete"
    [disabled]="disabled"
    (click)="$any($event).target.setSelectionRange(0, $any($event).target.value.length)"
    (keyup)="onSearchChange($any($event).target.value)"
  />
  @if (!loading) {
    <mat-icon matPrefix>search</mat-icon>
  }
  @if (loading) {
    <mat-icon matPrefix> <mat-spinner diameter="20"></mat-spinner></mat-icon>
  }
  @if (error) {
    <button matSuffix mat-icon-button class="retry-button" (click)="$event.stopPropagation(); retry()">
      <mat-icon>refresh</mat-icon>
    </button>
  }
  <mat-autocomplete
    #autocomplete="matAutocomplete"
    connectedTo="#searchInput"
    class="bg-white"
    (optionSelected)="onSearchSelect($event.option.value); searchInput.value = ''"
    (opened)="setIsOpenParam(true)"
    (closed)="setIsOpenParam(false)"
  >
    @if (!error && searchAutocomplete; as searchResults) {
      @if (searchResults && searchResults.length > 0) {
        @for (athlete of searchResults; track athlete) {
          <mat-option [value]="athlete" class="!text-sm sm:!text-base">
            {{ athlete?.bib }} - {{ athlete?.person?.lastName }}, {{ athlete?.person?.firstName }} ({{
              athlete?.person?.birthYear
            }}) - {{ athlete?.club?.abbr }} - {{ athlete?.person?.currentCategory }}
          </mat-option>
        }
      } @else {
        @if (isOpen === true && !loading) {
          <mat-option>{{ t('SEARCH_NO_RESULT_FOUND_FOR', { value: currentSearchValue }) }}</mat-option>
        }
      }
    } @else {
      @if (error) {
        <mat-option disabled class="error">{{ t('SEARCH_ERROR') }}</mat-option>
      }
    }
  </mat-autocomplete>
</mat-form-field>
