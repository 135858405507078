import { Component } from '@angular/core';
import { ILoadingOverlayParams, ILoadingOverlayComp } from '@ag-grid-community/core';
import { MatProgressSpinner } from '@angular/material/progress-spinner';

@Component({
  selector: 'beathletics-grid-loading-overlay',
  templateUrl: './grid-loading-overlay.component.html',
  styleUrls: ['./grid-loading-overlay.component.scss'],
  standalone: true,
  imports: [MatProgressSpinner],
})
export class GridLoadingOverlayComponent implements ILoadingOverlayComp {
  params!: ILoadingOverlayParams;

  agInit(params: ILoadingOverlayParams): void {
    this.params = params;
  }

  getGui(): HTMLElement {
    return document.createElement('<div>');
  }
}
