import { Injectable, inject } from '@angular/core';
import { Roles, PrismaValidation as Validation } from '@beathletics/api-interfaces';

import { Action, NgxsOnInit, Selector, State, StateContext } from '@ngxs/store';

import { KeycloakEventType, KeycloakService } from 'keycloak-angular';
import { AuthStateModel } from './auth.model';

export class SetStateStatus {
  static readonly type = '[Auth] Set State Status';
  constructor(public status: boolean) {}
}

export class SetAuthStatus {
  static readonly type = '[Auth] Set Authentification Status';
  constructor(public isLogged: boolean) {}
}

export class LoadProfile {
  static readonly type = '[Auth] Loading profile';
}

@State<AuthStateModel>({
  name: 'auth',
  defaults: {
    profile: undefined,
    isLogged: false,
    isReady: false,
    roles: [],
  },
})
@Injectable()
export class AuthState implements NgxsOnInit {
  private keycloak = inject(KeycloakService);

  static validationStatus = [
    {
      name: 'En attente',
      value: Validation.pending,
      role: Roles.Admin,
    },
    {
      name: 'Validé par le JA',
      value: Validation.ja_approved,
      role: Roles.Ja,
    },
    {
      name: 'Refusé par le JA',
      value: Validation.ja_not_approved,
      role: Roles.Ja,
    },
    {
      name: "Certifié par l'homologation",
      value: Validation.h_certified,
      role: Roles.Homologation,
    },
    {
      name: "Refusé par l'homologation",
      value: Validation.h_not_certified,
      role: Roles.Homologation,
    },
  ];

  @Selector()
  static email(state: AuthStateModel) {
    return state.profile?.email;
  }

  @Selector()
  static profile(state: AuthStateModel) {
    return (state.isReady && state.isLogged && state.profile) || undefined;
  }

  @Selector()
  static roles(state: AuthStateModel) {
    return /* state.isReady && state.isLogged && */ state.roles;
  }

  @Selector()
  static linkedClub(state: AuthStateModel) {
    return (state?.profile?.attributes?.club as string[])?.[0];
  }

  @Selector()
  static validationStatusByRole(state: AuthStateModel) {
    if (state.roles.includes(Roles.Admin_lbfa) || state.roles.includes(Roles.Admin)) {
      return this.validationStatus;
    } else {
      return this.validationStatus.filter((status) => state.roles.includes(status.role));
    }
  }

  ngxsOnInit(ctx: StateContext<AuthStateModel>) {
    this.keycloak.keycloakEvents$.subscribe({
      next: (value) => {
        switch (value.type) {
          case KeycloakEventType.OnReady:
            ctx.dispatch(new SetStateStatus(true));
            break;
          case KeycloakEventType.OnAuthSuccess:
            ctx.dispatch(new SetAuthStatus(true));
            break;
          case KeycloakEventType.OnAuthError:
            ctx.dispatch(new SetAuthStatus(false));
            break;
          case KeycloakEventType.OnAuthLogout:
            ctx.dispatch(new SetAuthStatus(false));
            break;
          case KeycloakEventType.OnAuthRefreshSuccess:
          case KeycloakEventType.OnAuthRefreshError:
          case KeycloakEventType.OnTokenExpired:
          default:
            break;
        }
      },
    });
  }

  @Action(SetStateStatus)
  setStateStatus(ctx: StateContext<AuthStateModel>, action: SetStateStatus) {
    ctx.patchState({ isReady: action.status });
  }

  @Action(SetAuthStatus)
  setAuthStatus(ctx: StateContext<AuthStateModel>, action: SetAuthStatus) {
    ctx.patchState({ isLogged: action.isLogged });
    if (action.isLogged) {
      const instance = this.keycloak.getKeycloakInstance();
      ctx.patchState({ roles: instance.realmAccess?.roles as Roles[] });
      return ctx.dispatch(new LoadProfile());
    } else {
      ctx.patchState({ profile: undefined });
    }
    return;
  }

  @Action(LoadProfile)
  async loadProfile(ctx: StateContext<AuthStateModel>) {
    return ctx.patchState({ profile: await this.keycloak.loadUserProfile() });
  }
}
