export enum EPerfType {
  TIME = 'time',
  DISTANCE = 'distance',
  POINTS = 'points',
  UNKNOWN = 'unknown',
}

export enum EImportSource {
  ALABUS_XLS = 'alabus-xls',
  AM_XML = 'am-xml',
}

export enum EFederationType {
  LBFA = 'BEL_LBFA',
  VAL = 'BEL_VAL',
  WA = 'INT-WA',
  WPA = 'INT-WPA',
}

export enum EContactSubject {
  BUG = 'bug',
  DATA = 'data',
  FEEDBACK = 'feedback',
  OTHER = 'other',
}

export enum ERegistrationOrderStatus {
  PENDING = 'PENDING',
  SUBMITTED = 'SUBMITTED',
  VALIDATED = 'VALIDATED',
  CANCELLED = 'CANCELLED',
  SUBMITTED_AND_PAY = 'SUBMITTED_AND_PAY',
  PENDING_PAYMENT = 'PENDING_PAYMENT',
}

export enum ERegistrationShippingStatus {
  PENDING = 'PENDING',
  SHIPPED = 'SHIPPED',
  DELIVERED = 'DELIVERED',
  NA = 'NA',
}

export enum ERegistrationItemStatus {
  PENDING = 'PENDING',
  VALIDATED = 'VALIDATED',
  REJECTED = 'REJECTED',
}

export enum ERegistrationLockType {
  INVITATION_ONLY = 'INVITATION_ONLY',
  ONSITE_REGISTRATION = 'ONSITE_REGISTRATION',
  CANCELLED_EVENT = 'CANCELLED_EVENT',
  FINAL_EVENT = 'FINAL_EVENT',
}

export enum EResultFormType {
  RACE = 'race',
  HIGH_JUMP = 'high-jump',
  THROW_AND_LJ = 'throw-and-lj',
  COMBINED_TOTAL = 'combined-total',
}

export enum ECompetitionFeature {
  DID_NOT_START = 'DNS',
  DID_NOT_FINISH = 'DNF',
  DISQUALIFIED = 'DQ',
  NOT_RECEIVABLE = 'NR',
  NO_MARK = 'NM',
  NO_HEIGHT = 'NH',
  YELLOW_CARD = 'yC',
  SECOND_YELLOW_CARD = 'yRC',
  RED_CARD = 'RC',
  FALSE_START = 'Fn',
  RETIRED_FROM_COMPETITION = 'R',
  QUALIFIED_BY_TRACK_OR_FIELD = 'Q',
  QUALIFIED_BY_PERFORMANCE = 'q',
  ADVANCED_TO_NEXT_ROUND_BY_REFEREE = 'qR',
  ADVANCED_TO_NEXT_ROUND_BY_JURY_OF_APPEAL = 'qJ',
  BENT_KNEE = '>',
  LOSS_OF_CONTACT = '~',
  /* AUTOMATIC_TIMING = 'a',
  HAND_TIMING = 'h',
  PERFORMANCE_ACHIEVED_AT_ALTITUDE = 'A', */
  OVERSIZED_TRACK = 'OT',
}

export enum ESelectionStatus {
  SELECTED = 'selected',
  NOT_SELECTED = 'not_selected',
  PENDING = 'pending',
}
