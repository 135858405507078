import { PrismaVenueType as VenueType } from '@beathletics/api-interfaces';
import { fr, enGB, nlBE } from 'date-fns/locale';
import { toZonedTime, format, fromZonedTime } from 'date-fns-tz';

export function getDateObjectFromDateString(dateString: string): Date | undefined {
  if (dateString && typeof dateString === 'string' && dateString.includes('-')) {
    const d = dateString.split('-');
    if (d && d.length === 3) {
      if (+d[2] > 31) {
        d.reverse();
      }
      return new Date(+d[0], +d[1] - 1, +d[2]);
    }
  }
  return undefined;
}

export function getDateStringFromDateObject(date: Date | undefined) {
  if (date) {
    const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
    const month = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
    return `${day}-${month}-${date.getFullYear()}`;
  } else {
    return '';
  }
}

export function getDateWithTimeFromDateAndTimeString(date: Date, time: string | undefined): Date | undefined {
  if (time && typeof time === 'string' && time.includes(':') && date && typeof date === 'object') {
    const t = time.split(':');
    return new Date(date.getFullYear(), date.getMonth(), date.getDate(), +t[0], +t[1], +t[2]);
  }
  return undefined;
}

export function getVenueTypeFromDate(date?: Date) {
  if (date) {
    const _date = new Date(date);
    if (_date.getMonth() + 1 < 11 && _date.getMonth() + 1 > 3) {
      return VenueType.Outdoor;
    } else {
      return VenueType.Indoor;
    }
  } else {
    return VenueType.Unknown;
  }
}

export function getDateTimeIsoStringFromDate(date: Date) {
  return new Date(date).toISOString().split('.')[0];
}

export const defaultTimeZone = 'Europe/Brussels';

export const localeToDateLang = {
  fr: fr,
  en: enGB,
  nl: nlBE,
};

export function convertDateForDateTimeLocalInput(date: Date, timeZone = defaultTimeZone) {
  return format(toZonedTime(date, defaultTimeZone), `yyyy-MM-dd'T'HH:mm:ss`, {
    timeZone,
  });
}

export function convertLocaleDateToUTCDate(date: string, timeZone = defaultTimeZone) {
  return fromZonedTime(date, timeZone);
}

export function getDateAsYYYYMMDDhhmmssString(date: Date) {
  const year = date.getFullYear();
  const month = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
  const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
  const hours = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours();
  const minutes = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();
  const seconds = date.getSeconds() < 10 ? `0${date.getSeconds()}` : date.getSeconds();
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

export function getDateAsYYYMMDDString(date: Date) {
  const offset = date.getTimezoneOffset();
  date = new Date(date.getTime() - offset * 60 * 1000);
  return date.toISOString().split('T')[0];
}

export function removeTimeZoneFromDate(date: Date) {
  const tzOffset = date.getTimezoneOffset() * 60000; //offset in milliseconds
  return new Date(date.valueOf() - tzOffset).toISOString().slice(0, -1) + 'Z';
}

export function removeSecondsFromTimeString(time?: string) {
  const t = time?.split(':');
  return t?.[0] && t?.[1] ? t[0] + ':' + t[1] : undefined;
}
