import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'priceFormatter',
  standalone: true,
})
export class PriceFormatterPipe implements PipeTransform {
  transform(value: number | null | undefined): string {
    if (value || value === 0) {
      return (value / 100).toFixed(2) + ' €';
    } else {
      return 'X';
    }
  }
}
