import { FocusMonitor } from '@angular/cdk/a11y';
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, ElementRef, HostBinding, Inject, Input, OnDestroy, Optional, Self, ViewChild } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  FormGroup,
  NgControl,
  ValidationErrors,
  ValidatorFn,
  Validators,
  ReactiveFormsModule,
} from '@angular/forms';
import { MAT_FORM_FIELD, MatFormField, MatFormFieldControl } from '@angular/material/form-field';
import { Subject } from 'rxjs';
import { MatInput } from '@angular/material/input';
import { NgxMaskDirective } from 'ngx-mask';

@Component({
  selector: 'beathletics-time-input',
  template: `
    <div
      class="time-input-container"
      [formGroup]="form"
      [attr.aria-labelledby]="_formField.getLabelId()"
      (focusin)="onFocusIn()"
      (focusout)="onFocusOut($event)"
    >
      <input
        matInput
        formControlName="time"
        mask="Hh:m0:s0.00||m0:s0.00||s0.00"
        [leadZeroDateTime]="true"
        [placeholder]="placeholder"
        (input)="handleInput($event)"
        (blur)="onBlur($event)"
        #timeInput
      />
    </div>
  `,
  providers: [{ provide: MatFormFieldControl, useExisting: TimeInputComponent }],
  standalone: true,
  imports: [ReactiveFormsModule, MatInput, NgxMaskDirective],
})
export class TimeInputComponent implements ControlValueAccessor, MatFormFieldControl<string | number>, OnDestroy {
  static nextId = 0;
  @HostBinding() id = `time-input-${TimeInputComponent.nextId++}`;

  @HostBinding('class.floating')
  get shouldLabelFloat(): boolean {
    return this.focused || !this.empty;
  }

  @ViewChild('timeInput') timeInput!: HTMLElement;

  form: FormGroup<{ time: FormControl<string | null> }>;
  stateChanges = new Subject<void>();
  focused = false;
  touched = false;
  controlType = 'time-input';
  possibleSeparators = [':', '.', ',', ';', ' ', '-', '/', '"', "'"];

  onChange!: (val: string | number | null) => void;
  onTouched!: () => void;

  // if value ends with a single digit after the separator '. or :', add a ending zero
  onBlur = (data: FocusEvent) => {
    if ((data.target as HTMLInputElement).value.match(/(\.|:)\d$/)) {
      (data.target as HTMLInputElement).value = (data.target as HTMLInputElement).value + '0';
      (data.target as HTMLInputElement).dispatchEvent(new InputEvent('input', { data: '0' }));
    }
  };

  get empty(): boolean {
    const {
      value: { time },
    } = this.form;
    return !time;
  }

  @Input()
  get placeholder(): string {
    return this._placeholder;
  }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  private _placeholder = '';

  @Input()
  get required(): boolean {
    return this._required;
  }
  set required(value: BooleanInput) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: BooleanInput) {
    this._disabled = coerceBooleanProperty(value);
    this._disabled ? this.form.disable() : this.form.enable();
    this.stateChanges.next();
  }
  private _disabled = false;

  @Input()
  get value(): string | number | null {
    if (this.form.valid) {
      const {
        value: { time },
      } = this.form;
      return time ? this.timeStringToMilliseconds(time) : null;
    }
    return null;
  }
  set value(time: string | number | null) {
    const _time = time ? this.millisecondsToTimeString(+time) : '';
    this.form.setValue({ time: _time });
    this.stateChanges.next();
  }

  get errorState(): boolean {
    return this.form.invalid && this.touched;
  }

  constructor(
    formBuilder: FormBuilder,
    private _focusMonitor: FocusMonitor,
    private _elementRef: ElementRef<HTMLElement>,
    @Optional() @Inject(MAT_FORM_FIELD) public _formField: MatFormField,
    @Optional() @Self() public ngControl: NgControl,
  ) {
    this.form = formBuilder.group({
      time: ['', [Validators.required]],
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;

      // Adding a custom validator to the ngControl which checks the validity
      // of the custom form field component.
      const validator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
        return this.form.valid ? null : { customControl: true };
      };
      if (this.ngControl.control) {
        this.ngControl.control.setValidators([
          validator,
          ...(this.ngControl.control.validator ? [this.ngControl.control.validator] : []),
        ]);
        this.ngControl.control.updateValueAndValidity();
      }
    }
  }

  ngOnDestroy(): void {
    this.stateChanges.complete();
    this._focusMonitor.stopMonitoring(this._elementRef);
  }

  onFocusIn(): void {
    if (!this.focused) {
      this.focused = true;
      this.stateChanges.next();
    }
  }

  onFocusOut(event: FocusEvent): void {
    if (!this._elementRef.nativeElement.contains(event.relatedTarget as Element)) {
      this.touched = true;
      this.focused = false;
      this.onTouched();
      this.stateChanges.next();
    }
  }

  onContainerClick(): void {
    this._focusMonitor.focusVia(this.timeInput, 'program');
  }

  writeValue(time: string | number | null): void {
    this.value = time;
  }

  registerOnChange(fn: (_: unknown) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  setDescribedByIds(ids: string[]): void {
    const controlElement = this._elementRef.nativeElement.querySelector('.time-input-container');
    if (controlElement) {
      controlElement.setAttribute('aria-describedby', ids.join(' '));
    }
  }

  handleInput(_event: Event): void {
    const event = _event as InputEvent;
    const value = this.form.value.time;

    if (value && value.length % 2 !== 0 && event.data && this.possibleSeparators.includes(event.data)) {
      this.form.patchValue({
        time: '0' + value,
      });
    }
    this.onChange(this.value);
  }

  millisecondsToTimeString(milliseconds: number): string {
    const hundredths = Math.floor((milliseconds % 1000) / 10);
    const seconds = Math.floor((milliseconds / 1000) % 60);
    const minutes = Math.floor((milliseconds / (1000 * 60)) % 60);
    const hours = Math.floor((milliseconds / (1000 * 60 * 60)) % 24);

    const hundredthsString = hundredths.toString().padStart(2, '0');
    const secondsString = seconds.toString().padStart(2, '0');
    const minutesString = minutes.toString().padStart(2, '0');
    const hoursString = hours.toString().padStart(2, '0');

    return (
      (hours > 0 ? hoursString + ':' : '') +
      (minutes > 0 ? minutesString + ':' : '') +
      secondsString +
      '.' +
      hundredthsString
    );
  }

  timeStringToMilliseconds(timeString: string): number {
    const hundredths = timeString.slice(-2);
    const seconds = timeString.slice(-4, -2);
    const minutes = timeString.length > 2 ? timeString.slice(-6, -4) : 0;
    const hours = timeString.length > 4 ? timeString.slice(-8, -6) : 0;

    return +hundredths * 10 + +seconds * 1000 + +minutes * 60000 + +hours * 3600000;
  }
}
