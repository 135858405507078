import { animate, state, style, transition, trigger } from '@angular/animations';
import { isPlatformBrowser } from '@angular/common';
import { Component, EventEmitter, InjectionToken, Input, Output, PLATFORM_ID, inject } from '@angular/core';
import { MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatMenu, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { PublicSearchResult } from '@beathletics/api-interfaces';
import { PublicSearchBarComponent } from '../public-search-bar/public-search-bar.component';

@Component({
  selector: 'beathletics-main-header',
  templateUrl: './main-header.component.html',
  animations: [
    trigger('triggerNavMenu', [
      state('closed', style({ height: '0px' })),
      state('open', style({ height: '60px' })),
      transition('closed <=> open', animate('400ms ease-in-out')),
    ]),
  ],
  standalone: true,
  imports: [
    RouterLink,
    RouterLinkActive,
    PublicSearchBarComponent,
    MatIconButton,
    MatIcon,
    MatMenuTrigger,
    MatMenu,
    MatMenuItem,
  ],
})
export class MainHeaderComponent {
  private platformId = inject<InjectionToken<unknown>>(PLATFORM_ID);

  @Input() theme_color!: {
    fill: string;
    background: string;
    font: string;
    font_colored?: string;
    border: string;
    themeId: string;
    svgId: string;
  };
  @Input() userProfile!: unknown | null;
  @Input() searchAutocomplete!: PublicSearchResult;
  @Input() featuresAccess: Record<string, boolean> = { rankings: true };

  @Output() logout = new EventEmitter();
  @Output() searchValue = new EventEmitter();
  @Output() contactForm = new EventEmitter();

  navState = 'closed';
  menuItems = [
    {
      link: ['/', 'home'],
      icon: 'home',
      text: 'Accueil',
    },
    {
      link: ['/', 'calendar'],
      icon: 'event',
      text: 'Calendrier',
    },
    {
      link: ['/', 'results'],
      icon: 'event_note',
      text: 'Résultats',
    },
    {
      link: ['/', 'rankings'],
      icon: 'format_list_numbered',
      text: 'Rankings',
      feature: 'rankings',
    },
  ];

  isBrowserRendering(): boolean {
    return isPlatformBrowser(this.platformId);
  }

  isLargeView(): boolean {
    if (this.isBrowserRendering()) {
      const win = window;
      const doc = document;
      const docElem = doc.documentElement;
      const body = doc.getElementsByTagName('body')[0];
      const x = win.innerWidth || docElem.clientWidth || body.clientWidth;
      if (x >= 900) {
        return true;
      } else {
        return false;
      }
    } else return false;
  }

  triggerMenu() {
    this.navState = this.navState === 'closed' ? 'open' : 'closed';
  }
}
