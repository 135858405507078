import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild, inject } from '@angular/core';
import { MatAutocompleteTrigger, MatAutocomplete } from '@angular/material/autocomplete';
import { MatFormFieldAppearance, MatFormField, MatLabel, MatPrefix, MatSuffix } from '@angular/material/form-field';
import { Router } from '@angular/router';
import { AthleteSearchResult } from '@beathletics/api-interfaces';
import { TranslocoDirective } from '@jsverse/transloco';
import { MatInput } from '@angular/material/input';
import { AutofocusDirective } from '../../utils/mat-input-autofocus.directive';
import { MatIcon } from '@angular/material/icon';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatIconButton } from '@angular/material/button';
import { MatOption } from '@angular/material/core';

@Component({
  selector: 'beathletics-athlete-search-bar',
  templateUrl: './athlete-search-bar.component.html',
  standalone: true,
  imports: [
    TranslocoDirective,
    MatFormField,
    MatLabel,
    MatInput,
    MatAutocompleteTrigger,
    AutofocusDirective,
    MatIcon,
    MatPrefix,
    MatProgressSpinner,
    MatIconButton,
    MatSuffix,
    MatAutocomplete,
    MatOption,
  ],
})
export class AthleteSearchBarComponent {
  router = inject(Router);

  isOpen = false;
  currentSearchValue: string | undefined;

  @ViewChild('searchInput', { read: MatAutocompleteTrigger, static: true })
  searchInput!: MatAutocompleteTrigger;
  @ViewChild('searchInput', { static: true })
  searchNativeElem!: ElementRef<HTMLInputElement>;

  @Input() action: 'NAVIGATE' | 'RETURN' = 'NAVIGATE';
  @Input() searchAutocomplete: AthleteSearchResult[] = [];
  @Input() loading = false;
  @Input() error = true;
  @Input() disabled = false;
  @Input() appearance: MatFormFieldAppearance = 'outline';
  @Input() removePadding = false;
  @Input() autofocus = false;

  @Output() searchValue = new EventEmitter();
  @Output() athleteToReturn = new EventEmitter<AthleteSearchResult>();

  onSearchChange(value: string) {
    if (value != this.currentSearchValue) {
      this.searchValue.next(value.trim());
      this.currentSearchValue = value.trim();
    }
    if (value.trim().length >= 3) {
      this.searchInput?.openPanel();
      this.isOpen = true;
    } else {
      this.searchInput?.closePanel();
      this.isOpen = false;
    }
  }

  onSearchSelect(value: AthleteSearchResult) {
    if (this.action === 'NAVIGATE') {
      this.router.navigate(['/', 'athlete', value.liveId]);
    }
    if (this.action === 'RETURN') {
      this.athleteToReturn.emit(value);
    }
  }

  setIsOpenParam(value: boolean) {
    this.isOpen = value;
  }

  retry() {
    this.searchValue.next(this.currentSearchValue);
  }
}
