import { Component } from '@angular/core';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'beathletics-row-edit-btn',
  templateUrl: './row-edit-btn.component.html',
  styleUrls: ['./row-edit-btn.component.scss'],
  standalone: true,
  imports: [MatIcon],
})
export class RowEditBtnComponent {
  value = 'edit';

  agInit() {
    this.value = 'edit';
  }
}
