<header
  class="z-10 relative bg-[length:100%_auto] lg:bg-none bg-bottom pb-[50px] md:pb-0 bg-no-repeat transition-all duration-400 bg-header-top-{{
    theme_color.svgId
  }}-mobile"
>
  <div
    class="items-start bg-auto bg-no-repeat md:min-h-[200px] lg:bg-header-ellipse lg:bg-header-top-{{
      theme_color.svgId
    }}-lg"
    [class.pb-30]="navState === 'open'"
  >
    <div class="p-5 grid items-center grid-cols-[200px_minmax(0px,460px)_auto]">
      <h1 class="header-title pl-3 text-left md:text-right !mr-5 {{ theme_color.font }}" [routerLink]="['/']">
        <a routerLinkActive="text-lighter" [routerLink]="['/', 'home']"
          ><span class="font-logo font-normal text-xl">Be</span
          ><span class="font-black font-logo text-xl">Athletics</span></a
        >
      </h1>
      <div
        class="col-span-3 col-start-1 md:col-start-2  row-start-3 md:row-start-1 md:col-span-1 {{ theme_color.font }}"
      >
        <beathletics-public-search-bar
          [searchAutocomplete]="searchAutocomplete"
          (searchValue)="searchValue.emit($event)"
        >
        </beathletics-public-search-bar>
      </div>
      <div class="flex justify-end">
        <button
          class="lg:!hidden"
          [style.color]="theme_color.themeId === 'home' ? '#000' : '#fff'"
          mat-icon-button
          (click)="triggerMenu()"
        >
          <mat-icon class="mb-1">menu</mat-icon>
        </button>
        <nav [class]="'nav-menu flex items-center gap-5 !font-bold !text-base ' + theme_color.font">
          @for (item of menuItems; track item) {
            @if (!item.feature || featuresAccess[item.feature]) {
              <a class="hidden lg:flex flex-row" routerLinkActive="text-lighter" [routerLink]="item.link">
                <mat-icon class="mr-2">{{ item.icon }}</mat-icon
                >{{ item.text }}
              </a>
            }
          }

          <button
            mat-icon-button
            class="!hidden lg:!inline-block !text-inherit"
            [style.boxShadow]="'none'"
            [matMenuTriggerFor]="beforeMenu"
          >
            <mat-icon>more_vert</mat-icon>
          </button>
        </nav>
      </div>
      <nav
        [@triggerNavMenu]="navState"
        class="row-start-2 col-span-full w-full mb-2 nav-menu triggered flex justify-evenly items-center overflow-hidden lg:!hidden"
        [style.color]="theme_color.themeId === 'home' ? '#000' : '#fff'"
      >
        @for (item of menuItems; track item) {
          @if (!item.feature || featuresAccess[item.feature]) {
            <a routerLinkActive="text-lighter" [routerLink]="item.link" class="flex flex-col md:flex-row items-center">
              <mat-icon class="mr-2">{{ item.icon }}</mat-icon
              ><span class="hidden xxs:flex">{{ item.text }}</span>
            </a>
          }
        }
        <a routerLinkActive="text-lighter" (click)="contactForm.emit()" class="flex flex-col md:flex-row items-center">
          <mat-icon class="mr-2">email</mat-icon><span class="hidden xxs:flex">Contact</span>
        </a>
      </nav>
    </div>
  </div>
</header>
<mat-menu #beforeMenu="matMenu" xPosition="before">
  <a mat-menu-item (click)="contactForm.emit()"> <mat-icon class="!text-gray-600">email</mat-icon>Contact </a>
  <a mat-menu-item [routerLink]="['/', 'admin']"> <mat-icon class="!text-gray-600">lock</mat-icon>Admin </a>
  @if (userProfile) {
    <button mat-menu-item (click)="logout.emit(true)">
      <mat-icon class="!text-gray-600">power_settings_new</mat-icon>Logout
    </button>
  }
</mat-menu>
