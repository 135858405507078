import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replace',
  standalone: true,
})
export class ReplacePipe implements PipeTransform {
  transform(value: string | number | null, toReplace: string, replaceBy: string): string {
    if (value) {
      // Escape special characters to avoid them to be interpreted as regex
      const escapedReplace = toReplace.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
      const regex = new RegExp(escapedReplace, 'g');
      return String(value).replace(regex, replaceBy);
    }
    return '';
  }
}
