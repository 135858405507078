import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { IClub } from '@beathletics/api-interfaces';
import type { EventType, NewCategory, NewCountry, NewFederation } from '@prisma/client';

@Injectable()
export class SharedApiService {
  private http = inject(HttpClient);

  getAllCategories = () => this.http.get<NewCategory[]>('/api/shared/categories');

  getAllClubs = () => this.http.get<IClub[]>('/api/shared/clubs');

  getAllCountries = () => this.http.get<NewCountry[]>('/api/shared/countries');

  getAllEventTypes = () => this.http.get<EventType[]>(`/api/shared/event-types`);

  getAllFederations = () => this.http.get<NewFederation[]>(`/api/shared/federations`);
}
