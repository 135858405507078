<div class="search-bar-container">
  @if (!searchAutocomplete.loading) {
<button mat-mini-fab class="search-button !bg-transparent
  min-h-[30px]" disabled>
      <mat-icon class="cursor-not-allowed">search</mat-icon>
    </button>
  }
  @if (searchAutocomplete.loading) {
    <button mat-mini-fab class="search-button !bg-transparent min-h-[30px]">
      <mat-spinner diameter="20"></mat-spinner>
    </button>
  }
  <input
    type="text"
    spellcheck="false"
    placeholder="Chercher un athlète, une compétition"
    matInput
    tabindex="0"
    class="search-bar"
    [class.panelOpen]="isOpen"
    #searchInput
    [matAutocomplete]="autocomplete"
    (click)="
      $any($event).target.setSelectionRange(0, $any($event).target.value.length)
    "
    (keyup)="onSearchChange($any($event).target.value)"
    />
  @if (searchAutocomplete.error) {
    <button mat-mini-fab class="retry-button min-h-[30px]" (click)="  $event.stopPropagation(); retry(); " >
      <mat-icon>refresh</mat-icon>
    </button>
  }
  <mat-autocomplete
    tabindex="1"
    #autocomplete="matAutocomplete"
    connectedTo="#searchInput"
    (optionSelected)="
      onSearchSelect($event.option.value); searchInput.value = ''
    "
    (opened)="setIsOpenParam(true)"
    (closed)="setIsOpenParam(false)"
    >
    @if (!searchAutocomplete.error || !searchAutocomplete.loading;) {
      @if (
        searchAutocomplete &&
        (searchAutocomplete.athletes.length > 0 ||
        searchAutocomplete.competitions.length > 0)) {
        @if (
          searchAutocomplete.athletes.length > 0 &&
          searchAutocomplete.competitions.length > 0
          ) {
          <div class="search-toggle">
            <mat-button-toggle-group
              [(ngModel)]="selectedSearch"
              class="w-full"
              >
              <mat-button-toggle value="athletes" class="w-1/2"
                >Athlètes</mat-button-toggle
                >
                <mat-button-toggle value="competitions" class="w-1/2"
                  >Événements</mat-button-toggle
                  >
                </mat-button-toggle-group>
              </div>
            }
            @if (
              selectedSearch === 'athletes' ||
              (searchAutocomplete.athletes.length > 0 &&
              searchAutocomplete.competitions.length === 0)
              ) {
              @for (athlete of searchAutocomplete.athletes; track athlete) {
                <mat-option
                  tabindex="1"
                  [value]="athlete"
                  class="search-result"
                  >
                  {{ athlete.person.firstName }} {{ athlete.person.lastName }} ({{
                  athlete.person.birthYear
                  }}) -
                  {{ athlete.club?.abbr || 'NA' }}
                </mat-option>
              }
            }
            @if (
              selectedSearch === 'competitions' ||
              (searchAutocomplete.competitions.length > 0 &&
              searchAutocomplete.athletes.length === 0)
              ) {
              @for (competition of searchAutocomplete.competitions; track competition) {
                <mat-option
                  [value]="competition"
                  class="search-result"
                  >
                  {{ competition.name }} ({{
                  competition.startDate | date : 'shortDate'
                  }})
                </mat-option>
              }
            }
          } @else {
            @if (isOpen === true) {
              @if (!searchAutocomplete.error && !searchAutocomplete.loading) {
                <mat-option class="search-result">
                  Aucun résultat trouvé pour "{{ currentSearchValue }}"</mat-option
                  >
                }
                @if (searchAutocomplete.error) {
                  <mat-option disabled   class="error">
                    Une erreur a eu lieu, veuillez réessayer.
                    </mat-option
                    >
                  }
                }
              }
            }

          </mat-autocomplete>
        </div>
