export enum Genders {
  Man = 'M',
  Woman = 'W',
}

export enum Languages {
  Fr = 'fr',
  Nl = 'nl',
  De = 'de',
  En = 'en',
}

export enum ResultFormTypes {
  Basic = 'basic',
  Combined = 'combined',
  HighJump = 'high-jump',
}

export enum MailStatus {
  New = 'new',
  Sent = 'sent',
  Errored = 'errored',
}

export enum FtpFileStatus {
  Pending = 'pending',
  Imported = 'imported',
  Errored = 'errored',
}

export enum DisciplineImportType {
  Classic = 'classic',
  Relay = 'relay',
  Combined = 'combined',
  Unexpected = 'unexpected',
}

export enum DisciplineGroups {
  Sprint = 'Sprint',
  Fond = 'Fond',
  Demi_fond = 'Demi-fond',
  Haies = 'Haies',
  Relais = 'Relais',
  Saut = 'Saut',
  Lancer = 'Lancer',
  Multiple = 'Epreuves multiples',
}

export enum ResultTypes {
  Distance = 'Distance',
  Points = 'Points',
  Time = 'Time',
}

export enum Roles {
  User = 'default-roles-beathletics',
  Secretary = 'secretary',
  Ja = 'ja',
  Homologation = 'homologation',
  Admin_lbfa = 'admin_lbfa',
  Admin = 'admin',
  Statistics = 'statistics',
  Delegate = 'delegate',
}

export enum Validation {
  Pending = 'pending',
  JA_approved = 'ja_approved',
  JA_not_approved = 'ja_not_approved',
  H_certified = 'h_certified',
  H_not_certified = 'h_not_certified',
}

export enum JobType {
  /* DATA EXTRACTOR */
  'UploadedFile' = 'Uploaded File',
  'FedInsideSync' = 'Fed Inside Sync',
  'WebFile' = 'Web Files Sync',
  'FTPResultSync' = 'FTPResultSync',
  'FTPResultProcess' = 'FTPResultProcess',
  'LinkResultsWithEventType' = 'Link Results With EventType',
  /* DB ASYNC JOB */
  'VerifyCompetitionForRecords' = 'Verify Competition For Records',
  'VerifyResultsForRecords' = 'Verify Results For Records',
  'VerifyAllAthletesRecords' = 'Verify All Athletes For Records',
  'VerifyDisciplinesVenues' = 'Verify Disciplines Venues',
  'CorrectResultsTc' = 'Correct Results Tc',
  'CorrectHurdlesEventType' = 'Correct Hurdles EventType',

  /* DB NEW Models */
  'CopyResultsToNewModel' = 'Copy Results To NewModel',
  'CopyAthletesToNewModel' = 'Copy Athletes To NewModel',
  'CopyCompetitionsToNewModel' = 'CopyCompetitionsToNewModel',
  'CorrectVenueInconsistency' = 'Correct Venue Inconsistency',
  'AddCertifiableFlagOnResults' = 'Add Certifiable Flag On Results',
}

export enum DataType {
  CalendarVal = 'CalendarVal',
  CalendarLbfa = 'CalendarLbfa',
  //Referential = 'Referential',
  RankingRef = 'RankingRef',
  NewReferential = 'NewReferential',
  AthletesCSV = 'AthletesCSV',
  AthletesDossardXls = 'AthletesDossardXls',
  ResultsAlabus = 'ResultsAlabus',
  ResultsAlabusByMonth = 'ResultsAlabusByMonth',
  ResultsAlabusVeryBig = 'ResultsAlabusVeryBig',
  ResultsXML = 'ResultsXML',
  DisciplinesAlabus = 'DisciplinesAlabus',
  UsersXls = 'UsersXLS',
}

export enum FedInsideQuery {
  ATHLETE = 47,
  TERRAIN = 48,
  EVENT = 49,
  CLUB = 50,
  CATEGORY = 51,
  CALENDAR = 54,
}

export const FedInsideQueryName = {
  47: 'ATHLETE',
  48: 'TERRAIN',
  49: 'EVENT',
  50: 'CLUB',
  51: 'CATEGORY',
  54: 'CALENDAR',
};

export const PrismaGender = {
  Female: 'Female',
  Male: 'Male',
} as const;

export type PrismaGender = (typeof PrismaGender)[keyof typeof PrismaGender];

export const PrismaPaymentOption = {
  ON_SITE: 'ON_SITE',
  ONLINE: 'ONLINE',
  BOTH: 'BOTH',
} as const;

export type PrismaPaymentOption = (typeof PrismaPaymentOption)[keyof typeof PrismaPaymentOption];

export const PrismaPriceType = {
  ALL: 'ALL',
  SINGLE: 'SINGLE',
  RELAY: 'RELAY',
  COMBINED: 'COMBINED',
  OTHER: 'OTHER',
} as const;

export type PrismaPriceType = (typeof PrismaPriceType)[keyof typeof PrismaPriceType];

export const PrismaValidation = {
  pending: 'pending',
  ja_approved: 'ja_approved',
  ja_not_approved: 'ja_not_approved',
  h_certified: 'h_certified',
  h_not_certified: 'h_not_certified',
} as const;

export type PrismaValidation = (typeof PrismaValidation)[keyof typeof PrismaValidation];

export const PrismaDisciplineGroup = {
  Race: 'Race',
  Throws: 'Throws',
  HorizontalJumps: 'HorizontalJumps',
  VerticalJumps: 'VerticalJumps',
  CombinedDiscipline: 'CombinedDiscipline',
  Unknown: 'Unknown',
} as const;

export type PrismaDisciplineGroup = (typeof PrismaDisciplineGroup)[keyof typeof PrismaDisciplineGroup];

export const PrismaDisciplineImportType = {
  classic: 'classic',
  relay: 'relay',
  combined: 'combined',
  unexpected: 'unexpected',
} as const;

export type PrismaDisciplineImportType = (typeof PrismaDisciplineImportType)[keyof typeof PrismaDisciplineImportType];

export const PrismaVenueType = {
  Indoor: 'Indoor',
  Outdoor: 'Outdoor',
  Unknown: 'Unknown',
} as const;

export type PrismaVenueType = (typeof PrismaVenueType)[keyof typeof PrismaVenueType];

export const PrismaTC_TYPE = {
  CADET_TO_MASTER: 'CADET_TO_MASTER',
  SCOLAR_TO_MASTER: 'SCOLAR_TO_MASTER',
} as const;

export type PrismaTC_TYPE = (typeof PrismaTC_TYPE)[keyof typeof PrismaTC_TYPE];

export const PrismaMailStatus = {
  new: 'new',
  sent: 'sent',
  errored: 'errored',
} as const;

export type PrismaMailStatus = (typeof PrismaMailStatus)[keyof typeof PrismaMailStatus];

export const PrismaCronTaskType = {
  urlPing: 'urlPing',
} as const;

export type PrismaCronTaskType = (typeof PrismaCronTaskType)[keyof typeof PrismaCronTaskType];

export const PrismaRegistrationRuleType = {
  PRIORITY: 'PRIORITY',
  QUOTA: 'QUOTA',
  LOCK: 'LOCK',
  AGE: 'AGE',
  LIMITATION: 'LIMITATION',
} as const;

export type PrismaRegistrationRuleType = (typeof PrismaRegistrationRuleType)[keyof typeof PrismaRegistrationRuleType];

export const PrismaContactFunction = {
  Correspondent: 'Correspondent',
  Secretary: 'Secretary',
  Director: 'Director',
  Ja: 'Ja',
  Homologation: 'Homologation',
} as const;

export type PrismaContactFunction = (typeof PrismaContactFunction)[keyof typeof PrismaContactFunction];
