import { Roles, PrismaContactFunction as ContactFunction } from '@beathletics/api-interfaces';

export function getUserHigherRole(roles: Roles[]): Roles {
  if (roles.includes(Roles.Admin)) {
    return Roles.Admin;
  } else if (roles.includes(Roles.Admin_lbfa)) {
    return Roles.Admin_lbfa;
  } else if (roles.includes(Roles.Homologation)) {
    return Roles.Homologation;
  } else if (roles.includes(Roles.Ja)) {
    return Roles.Ja;
  } else if (roles.includes(Roles.Secretary)) {
    return Roles.Secretary;
  } else return Roles.User;
}

export function convertRoleToContactFunction(role: Roles): ContactFunction {
  if (role === Roles.Secretary) {
    return ContactFunction.Secretary;
  } else if (role === Roles.Ja) {
    return ContactFunction.Ja;
  } else if (role === Roles.Homologation) {
    return ContactFunction.Homologation;
  } else {
    return ContactFunction.Correspondent;
  }
}
