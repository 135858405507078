import { isPlatformServer } from '@angular/common';
import { Directive, Input, PLATFORM_ID, TemplateRef, ViewContainerRef, inject } from '@angular/core';
import { Roles } from '@beathletics/api-interfaces';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, filter, switchMap } from 'rxjs';
import { AuthService } from './auth.service';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[hasRole]',
})
@UntilDestroy()
export class HasRoleDirective {
  private templateRef = inject<TemplateRef<any>>(TemplateRef);
  private viewContainer = inject(ViewContainerRef);
  private auth = inject(AuthService);
  // if platformId is server always return false
  private platformId = inject(PLATFORM_ID);

  @Input()
  set hasRole(role: Roles) {
    this.roles$.next(role);
  }

  roles$ = new BehaviorSubject<Roles>('' as Roles);
  isShow = false;

  constructor() {
    if (isPlatformServer(this.platformId)) {
      this.viewContainer.clear();
      this.isShow = false;
    } else {
      this.roles$
        .pipe(
          untilDestroyed(this),
          filter((role) => Object.values(Roles).includes(role)),
          switchMap((role: Roles) => this.auth.hasRole(role)),
        )
        .subscribe((isAuthorized) => {
          if (isAuthorized) {
            if (!this.isShow) {
              this.viewContainer.createEmbeddedView(this.templateRef);
              this.isShow = true;
            }
          } else {
            this.viewContainer.clear();
            this.isShow = false;
          }
        });
    }
  }
}
