import { Component, inject } from '@angular/core';
import { FormBuilder, Validators, ReactiveFormsModule } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslocoDirective } from '@jsverse/transloco';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatButton } from '@angular/material/button';

@Component({
  selector: 'beathletics-email-input-dialog',
  template: `
    <ng-container *transloco="let t">
      <div class="w-full  flex flex-col items-center justify-center">
        <form [formGroup]="emailForm" (ngSubmit)="submit()" class="w-full">
          <div class="text-2xl font-bold">Edition de l'adresse de contact</div>
          <mat-form-field class="w-full">
            <mat-label>{{ t('emailDialog.emailLabel') }}</mat-label>
            <input matInput formControlName="email" type="email" required />
          </mat-form-field>

          <div class="flex justify-end mt-4">
            <button mat-button type="button" (click)="close()" class="mr-2">
              {{ t('emailDialog.cancel') }}
            </button>
            <button mat-raised-button color="primary" type="submit" [disabled]="emailForm.invalid">
              {{ t('emailDialog.submit') }}
            </button>
          </div>
        </form>
      </div>
    </ng-container>
  `,
  styles: [],
  standalone: true,
  imports: [TranslocoDirective, ReactiveFormsModule, MatFormField, MatLabel, MatInput, MatButton],
})
export class EmailInputDialogComponent {
  private fb = inject(FormBuilder);
  public dialogRef = inject(MatDialogRef<EmailInputDialogComponent>);

  emailForm = this.fb.group({
    email: ['', [Validators.required, Validators.email]],
  });

  submit() {
    if (this.emailForm.valid) {
      this.dialogRef.close({ email: this.emailForm.value.email });
    }
  }

  close() {
    this.dialogRef.close();
  }
}
