<ng-container *transloco="let t">
  <section>
    <header class="text-center mb-6">
      <h2 class="text-lg font-medium mb-2">
        BeAthletics&nbsp; - &nbsp;{{ t('BASIC_CONTACT_FORM') }}
      </h2>
      <span class="text-sm" [innerHTML]="t('RANKING_PERF_LINK')"></span>
      <hr class="mt-3" />
    </header>
    <form [formGroup]="contactForm">
      <div class="flex w-full mb-3">
        <mat-form-field class="w-[calc(50%-8px)] mr-4">
          <mat-label>{{ t('BASIC_NAME') }}</mat-label>
          <input matInput formControlName="lastName" required />
          @if (contactForm.get('lastName')?.errors?.required) {
            <mat-error>{{
              t('ERROR_REQUIRED_FIELD')
            }}</mat-error>
          }
        </mat-form-field>

        <mat-form-field class="w-[calc(50%-8px)]">
          <mat-label>{{ t('BASIC_FIRSTNAME') }}</mat-label>
          <input matInput formControlName="firstName" required />
          @if (contactForm.get('firstName')?.errors?.required) {
            <mat-error>{{
              t('ERROR_REQUIRED_FIELD')
            }}</mat-error>
          }
        </mat-form-field>
      </div>

      <mat-form-field class="w-full mb-3">
        <mat-label>{{ t('BASIC_EMAIL_ADDRESS') }}</mat-label>
        <input matInput formControlName="email" required />
        <mat-hint class="text-[10px] xs:text-xs">{{
          t('event.registration.CHECK_YOUR_EMAIL')
        }}</mat-hint>
        @if (
          contactForm.get('email')?.errors?.email ||
          contactForm.get('email')?.errors?.pattern
          ) {
          <mat-error
            >
            {{ t('ERROR_INVALID_EMAIL') }}
          </mat-error>
        }
        @if (contactForm.get('email')?.errors?.required) {
          <mat-error>{{
            t('ERROR_REQUIRED_FIELD')
          }}</mat-error>
        }
      </mat-form-field>

      <mat-form-field class="w-full mb-3">
        <mat-label>{{ t('BASIC_SUBJECT') }}</mat-label>
        <mat-select formControlName="subject" required>
          @for (subject of subjects; track subject) {
            <mat-option [value]="subject">{{
              t('CONTACT_SUBJECT_' + subject)
            }}</mat-option>
          }
        </mat-select>
        @if (contactForm.get('subject')?.errors?.required) {
          <mat-error>{{
            t('ERROR_REQUIRED_FIELD')
          }}</mat-error>
        }
      </mat-form-field>

      <mat-form-field class="w-full mb-3">
        <mat-label>{{ t('BASIC_MESSAGE') }}</mat-label>
        <textarea matInput formControlName="message" required></textarea>
        <mat-hint class="text-[10px] xs:text-xs">{{
          t('CONTACT_HINT_TEXTAREA')
        }}</mat-hint>
        @if (contactForm.get('message')?.errors?.required) {
          <mat-error>{{
            t('ERROR_NO_CONTACT_MSG')
          }}</mat-error>
        }
      </mat-form-field>

      <div class="flex justify-between mt-3">
        <button mat-raised-button mat-dialog-close color="warn">
          {{ t('BASIC_CANCEL') }}
        </button>
        <button
          mat-raised-button
          color="primary"
          [disabled]="contactForm.invalid"
          (click)="sendContactEmail()"
          >
          {{ t('BASIC_SEND') }}
        </button>
      </div>
    </form>
  </section>
</ng-container>
