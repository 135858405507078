import { Component, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogClose } from '@angular/material/dialog';
import { TranslocoDirective } from '@jsverse/transloco';
import { MatButton } from '@angular/material/button';

@Component({
  selector: 'beathletics-confirmation-dialog',
  template: `
    <section *transloco="let t" class="min-w-[16rem] max-w-[24rem]">
      <header class="text-center">
        <h2 class="text-xl font-medium">{{ t(data.title) }}</h2>
        <hr class="my-4" />
      </header>
      <div class="text-center">
        <p [innerHTML]="t(data.message)"></p>
      </div>
      <footer class="flex justify-between mt-6">
        <button mat-raised-button [mat-dialog-close]="false" color="warn" class="w-24">
          {{ t('BASIC_CANCEL') }}
        </button>
        <button mat-raised-button color="primary" class="w-24" [mat-dialog-close]="true">
          {{ t('BASIC_CONFIRM') }}
        </button>
      </footer>
    </section>
  `,
  standalone: true,
  imports: [TranslocoDirective, MatButton, MatDialogClose],
})
export class ConfirmationDialogComponent {
  public data: { title: string; message: string } = inject(MAT_DIALOG_DATA);
}
