import { ChangeDetectionStrategy, Component, OnInit, inject } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';

export interface FormlyDialogData {
  model: object;
  fields: FormlyFieldConfig[];
}

@Component({
  selector: 'beathletics-formly-dialog',
  template: `
    <form [formGroup]="form" (ngSubmit)="onSubmit(data.model)">
      <formly-form [form]="form" [fields]="data.fields" [model]="data.model"></formly-form>
      <button type="submit" class="btn btn-default">Submit</button>
    </form>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ReactiveFormsModule, FormlyModule],
})
export class FormlyDialogComponent {
  dialogRef = inject<MatDialogRef<FormlyDialogComponent>>(MatDialogRef);
  data = inject<FormlyDialogData>(MAT_DIALOG_DATA);

  form = new FormGroup({});

  onSubmit(model: object) {
    this.dialogRef.close(model);
  }
}
